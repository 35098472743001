import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";
import { Spin, Switch } from "antd";
import { isEnabledAds, getSettings } from "../user/actions/userActions";

class SettingsComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isEnabledAdsState: false,
    };
  }

  isEnabledAds(check) {
    let values = {
      isTopSportEnabled: check,
    };
    this.setState({
      isEnabledAdsState: check,
    });
    this.props.isEnabledAds(values);
  }

  async componentDidMount() {
    this.setState({ loading: true });

    try {
      const settingData = await this.props.getSettings();
      if (settingData && settingData.length > 0) {
        this.setState({
          loading: false,
          isEnabledAdsState: settingData?.[0]?.isTopSportEnabled,
        });
      } else {
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error("Error:", error);
      this.setState({ loading: false });
    }
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      loading: false,
    });
  }
  render() {
    return (
      <>
        <h2 className="page-heading">Settings</h2>
        <div className="box box-default mb-4">
          <Spin spinning={this.state.loading} delay={500}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <p>Enable Top Sports Ads</p>
              <Switch
                checked={this.state.isEnabledAdsState}
                onChange={this.isEnabledAds.bind(this)}
              />
            </div>
          </Spin>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps, {
  isEnabledAds,
  getSettings,
})(SettingsComp);

